import {Link} from 'react-router-dom'
import {useContext} from 'react'
import {useHubspotForm} from '@aaronhayes/react-use-hubspot-form'

import SignUpBanner from '../SignUpBanner'

import {getUrl} from '../../utils/url-helper'
import {PAGE_PATHS} from '../../utils/constants'

import {authenticationContext} from '../../contexts/authentication'
import {endpointContext} from '../../contexts/endpoint'

import sources from '../../assets/images/graphics/sources.svg'

const {
  REACT_APP_HUBSPOT_ADDSOURCE_FORM_FORM_ID,
  REACT_APP_HUBSPOT_ADDSOURCE_FORM_PORTAL_ID,
  REACT_APP_HUBSPOT_ADDSOURCE_FORM_REGION,
} = process.env

function AddSource() {
  useHubspotForm({
    formId: REACT_APP_HUBSPOT_ADDSOURCE_FORM_FORM_ID,
    portalId: REACT_APP_HUBSPOT_ADDSOURCE_FORM_PORTAL_ID,
    region: REACT_APP_HUBSPOT_ADDSOURCE_FORM_REGION,
    target: '#hubspot-add-source-form',
  })

  const {selected} = useContext(endpointContext)
  const {user} = useContext(authenticationContext)

  return (
    <>
      <div className="app-focal">
        <section className="app-focal-overview">
          <div className="addSourceForm">
            <header className="addSourceHeader">
              <Link
                to={getUrl(PAGE_PATHS.list, selected)}
                className="button back-cta mb-1"
              >
                <span className="has-larrow">Cancel</span>
              </Link>

              <div className="addSourceHeader-icon">
                <img src={sources} height="46" alt="" />
              </div>
              <div className="addSourceHeader-content">
                <h1>Add your data source</h1>
                <p className="lead">
                  <strong>
                    Coming Soon: Add your SQL, noSQL, REST, or GraphQL data
                    source with StepZen introspection
                  </strong>
                </p>

                <p>
                  For now, let us know which SaaS API you want to build into
                  your StepZen GraphQL API. We’ll take care of the REST.
                </p>
              </div>
            </header>
            <div className="hubspotForm" id="hubspot-add-source-form" />
          </div>
        </section>
        {user ? null : (
          <aside className="app-focal-aside">
            <SignUpBanner />
          </aside>
        )}
      </div>
    </>
  )
}

export default AddSource
