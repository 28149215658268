import {createContext, useEffect, useState} from 'react'
import ReactGA from 'react-ga'

const analyticsContext = createContext()

const {REACT_APP_ANALYTICS_STEPZEN_API_URL, REACT_APP_REACTGA_ID} = process.env

const AnalyticsProvider = props => {
  const [initialized, setInitialized] = useState(false)
  const [userId, setUserID] = useState('unknown')

  // This is a wrapper for pageviews
  // The first page view, it must initialize GA
  const pageview = url => {
    if (!initialized) {
      ReactGA.initialize(REACT_APP_REACTGA_ID)
      ReactGA.ga(tracker => {
        setUserID(tracker.get('clientId'))
      })
      ReactGA.ga('set', 'userId', `${userId}`)
      setInitialized(true)
    }

    ReactGA.pageview(`${url}`)
  }

  // This is the click handler on document.body that tracks analytics
  useEffect(() => {
    const handler = async evt => {
      // The current date / time
      const now = new Date().toLocaleString('en-US', {
        timeZone: 'America/Los_Angeles',
      })

      // Get the closest element that we've explicitly marked.
      // If there isn't one, just get the nearest element.
      const el =
        evt?.target?.closest('[data-analytics-action]') ||
        evt?.target?.closest('*')

      // Get the full DOM path by traversing up the tree
      let path = []
      let ref = el

      do {
        const tag = ref.tagName.toLowerCase()
        const id = ref.id ? `#${ref.id}` : ''
        let classes = []
        ref.classList.forEach(c => classes.push(`.${c}`))

        path = [`${tag}${classes.join('')}${id}`, ...path]
        ref = ref.parentNode
      } while (ref.parentNode)

      path = path.join(' > ')

      // Create an action object. It's either our specifically marked one,
      // or derived from the event
      const action = {
        path: el.dataset.analyticsData || path,
        type: el.dataset.analyticsAction || evt.type,
      }

      // This is the query we are sending
      const query = `
        query analyticsEvent {
          amplitude_event(
            eventTime: "${now ? now : 'STUDIO_ERROR: now returned nothing'}"
            eventType: "${
              action.type
                ? action.type
                : 'STUDIO_ERROR: field action.type is not present'
            }"
            path: "${
              action.path
                ? action.path
                : 'STUDIO_ERROR: field action.path is not present'
            }"
            userId: "${
              userId ? userId : 'STUDIO_ERROR: field userId is not present'
            }"
          ) {
            code
            events_ingested
            payload_size_bytes
            server_upload_time
          }
        }
      `

      if (REACT_APP_ANALYTICS_STEPZEN_API_URL) {
        await fetch(REACT_APP_ANALYTICS_STEPZEN_API_URL, {
          body: JSON.stringify({query}),
          headers: {
            'Content-Type': 'application/json',
          },
          method: 'POST',
        })
      }
    }

    document.body.addEventListener('click', handler)

    return () => {
      document.body.removeEventListener('click', handler)
    }
  })

  const state = {
    pageview,
    userId,
  }

  return (
    <analyticsContext.Provider value={state}>
      {props.children}
    </analyticsContext.Provider>
  )
}

export {analyticsContext, AnalyticsProvider}
