import {createContext, useEffect, useState} from 'react'

import {COMBINATIONS_ENDPOINT, TEMPLATES_ENDPOINT} from '../utils/constants'

const {REACT_APP_SERVER_URL} = process.env

const templatesContext = createContext()

const TemplatesProvider = props => {
  const [combinations, setCombinations] = useState([])
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(true)
  const [selected, setSelected] = useState([])
  const [templates, setTemplates] = useState([])

  const {pathname} = window.location
  const initial = pathname.substring(1)?.split(',')

  useEffect(() => {
    ;(async () => {
      try {
        const response = await fetch(
          `${REACT_APP_SERVER_URL}${TEMPLATES_ENDPOINT}`,
        )
        const json = await response.json()

        setLoading(false)
        setTemplates(json)

        const selection = json.filter(template =>
          initial.includes(template.name),
        )
        setSelected(selection)
      } catch {
        setError('An error occurred')
      }
    })()
    ;(async () => {
      try {
        const response = await fetch(
          `${REACT_APP_SERVER_URL}${COMBINATIONS_ENDPOINT}`,
        )
        const json = await response.json()

        setCombinations(json)
        setLoading(false)
      } catch {
        setError('An error occurred')
      }
    })()
  }, [])

  const state = {
    combinations,
    error,
    loading,
    selected,
    setSelected,
    templates,
  }

  return (
    <templatesContext.Provider value={state}>
      {props.children}
    </templatesContext.Provider>
  )
}

export {templatesContext, TemplatesProvider}
