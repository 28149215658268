import {useState} from 'react'

import Combinations from './Combinations'
import Templates from './Templates'

import icons from '../../assets/images/icons.svg'

function TemplateList() {
  const [tab, setTab] = useState(0)

  return (
    <>
      <div role="tablist" className="tabs">
        <button
          aria-controls="TemplateListTemplates"
          aria-selected={tab === 0}
          className="button tab"
          id="TemplateListTemplatesTrigger"
          onClick={() => setTab(0)}
          role="tab"
        >
          <span>
            <svg className="icon" aria-hidden="true">
              <use xlinkHref={`${icons}#grid`}></use>
            </svg>
            All Schemas
          </span>
        </button>
        <button
          aria-controls="TemplateListCombinations"
          aria-selected={tab === 1}
          className="button tab"
          id="TemplateListCombinationsTrigger"
          onClick={() => setTab(1)}
          role="tab"
        >
          <span>
            <svg className="icon" aria-hidden="true">
              <use xlinkHref={`${icons}#combo`}></use>
            </svg>
            Combinations
          </span>
        </button>
      </div>

      <div className="scrollable">
        {tab === 0 ? <Templates /> : null}
        {tab === 1 ? <Combinations /> : null}
      </div>
    </>
  )
}

export default TemplateList
