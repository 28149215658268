import {Link} from 'react-router-dom'
import {useContext} from 'react'

import Endpoint from '../Endpoint'
import SignUpBanner from '../SignUpBanner'

import {getUrl} from '../../utils/url-helper'
import {PAGE_PATHS} from '../../utils/constants'

import {authenticationContext} from '../../contexts/authentication'
import {endpointContext} from '../../contexts/endpoint'

function Publish() {
  const {schema, selected} = useContext(endpointContext)
  const {user} = useContext(authenticationContext)

  return (
    <div className="app-focal">
      <section className={'app-focal-overview' + (schema ? '' : ' empty')}>
        {schema ? (
          <div className="publishBox">
            <header className="publishBox-header">
              <Link
                to={getUrl(PAGE_PATHS.list, selected)}
                className="button back-cta mb-1"
              >
                <span className="has-larrow">Cancel</span>
              </Link>
              <h1>Use your GraphQL API</h1>
              <p className="lead">
                You've added{' '}
                <strong className="countHighlight">{selected.length}</strong>{' '}
                schema
                {selected.length > 0 ? 's' : ''}. Access your API via the URL
                below, explore in GraphiQL, or download sample project code.
              </p>
            </header>

            <Endpoint />
          </div>
        ) : (
          <div className="app-focal-empty">
            <h1>Connect schemas to power your GraphQL API</h1>
            <Link to="/" className="button secondary">
              <span>Select Schemas</span>
            </Link>
          </div>
        )}
      </section>
      {!user ? (
        <aside className="app-focal-aside">
          <SignUpBanner />
        </aside>
      ) : null}
    </div>
  )
}

export default Publish
