import {Link} from 'react-router-dom'
import {useContext} from 'react'

import {endpointContext} from '../../contexts/endpoint'

import {getUrl} from '../../utils/url-helper'
import {PAGE_PATHS} from '../../utils/constants'

import icons from '../../assets/images/icons.svg'
import sources from '../../assets/images/graphics/sources.svg'

function AddSourceBanner() {
  const {selected} = useContext(endpointContext)

  return (
    <section
      className="addBanner"
      role="banner"
      data-analytics-action="addSource"
      data-analytics-data="Connect data source"
    >
      <div className="addBanner-wrap">
        <header className="addBanner-header">
          <h2 className="addBanner-headline">Connect your own data source</h2>
          <img src={sources} height="30" alt="" />
        </header>
        <Link
          to={getUrl(PAGE_PATHS.addSource, selected)}
          aria-label="Connect your own data source"
          className="icon-button color9 cover"
        >
          <span>
            <svg className="icon" aria-hidden="true">
              <use xlinkHref={`${icons}#plus`}></use>
            </svg>
          </span>
        </Link>
      </div>
    </section>
  )
}

export default AddSourceBanner
