import {PAGE_PATHS} from './constants'

export const isAddSource = params => {
  const {section} = processParams(params)
  return section === PAGE_PATHS.addSource
}

export const isListPage = params => {
  const {section} = processParams(params)
  return !section
}

export const isPublishPage = params => {
  const {section} = processParams(params)
  return section === PAGE_PATHS.publish
}

export const isTutorialPage = params => {
  const {section} = processParams(params)
  return section === PAGE_PATHS.tutorial
}

export const processParams = params => {
  const {param1, param2} = params
  let list = null

  if (!param1 && !param2) {
    return {list, section: null}
  }

  if (param2) {
    list = param2.split(',')
  } else if (
    [
      PAGE_PATHS.addSource,
      PAGE_PATHS.list,
      PAGE_PATHS.publish,
      PAGE_PATHS.tutorial,
    ].includes(param1) === false
  ) {
    list = param1.split(',')
  }

  const section = [
    PAGE_PATHS.addSource,
    PAGE_PATHS.list,
    PAGE_PATHS.publish,
    PAGE_PATHS.tutorial,
  ].includes(param1)
    ? param1
    : ''

  return {
    list,
    section,
  }
}

export const getUrl = (page, selected) => {
  const templates = selected.map(s => s.name).join(',')
  const url = `/${page}/${templates}`.replace('//', '/')
  return url
}

export const updateUrl = templates => {
  const current = window.location.pathname.substring(1)
  const urls = Object.values(PAGE_PATHS).filter(url => url)

  let list = templates.map(t => t.name)
  list.sort()

  const prefix = urls.find(url => current.startsWith(url)) || ''

  const path = `/${prefix}/${list.join(',')}`.replace('//', '/')
  const url = `${window.location.origin}${path}`

  if (window.location.href !== url) {
    window.history.pushState({}, document.title, url)
  }
}
