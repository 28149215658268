import {useContext} from 'react'

import {endpointContext} from '../../../contexts/endpoint'
import {templatesContext} from '../../../contexts/templates'

import backgroundSwatch from '../../../utils/background-swatch'

import icons from '../../../assets/images/icons.svg'

function Templates() {
  const {addSelected, isSelected} = useContext(endpointContext)
  const {templates} = useContext(templatesContext)

  return (
    <div id="TemplateListTemplates">
      <h2 className="sr-only">All Schemas</h2>
      <ul className="object-list">
        {templates.map(template =>
          isSelected(template.name) ? null : (
            <li key={`schema-item-${template.name}`}>
              <article className="card schema-card">
                <div className="card-media">
                  <span
                    className="schema-card-icon"
                    style={{
                      backgroundImage: backgroundSwatch(
                        template.metadata.colors,
                      ),
                    }}
                  ></span>
                </div>
                <header className="card-header">
                  <h3 className="card-title">{template.metadata.name}</h3>
                </header>
                <button
                  className="card-action-button icon-button color4 cover"
                  onClick={() => addSelected(template.name)}
                  aria-label={`Add ${template.metadata.name} to your endpoint.`}
                  data-analytics-action="schema"
                  data-analytics-data={template.metadata.name}
                >
                  <span>
                    <svg className="icon" aria-hidden="true">
                      <use xlinkHref={`${icons}#plus`}></use>
                    </svg>
                  </span>
                </button>
              </article>
            </li>
          ),
        )}
      </ul>
    </div>
  )
}

export default Templates
