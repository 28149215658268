import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import {HubspotProvider} from '@aaronhayes/react-use-hubspot-form'
import React from 'react'
import ReactDOM from 'react-dom'

import {AnalyticsProvider} from './contexts/analytics'
import {AuthenticationProvider} from './contexts/authentication'
import {EndpointProvider} from './contexts/endpoint'
import {SettingsProvider} from './contexts/settings'
import {StatusProvider} from './contexts/status'
import {TemplatesProvider} from './contexts/templates'

import MaintenancePage from './pages/MaintenancePage'
import PlayPage from './pages/PlayPage'

import 'tippy.js/dist/tippy.css'
import './assets/css/styles.css'

const {REACT_APP_MAINTENANCE_MODE} = process.env

// This forces the docs to be closed, when you refresh / start the experience
window.localStorage.removeItem('graphiql:docExplorerOpen')
// This forces mocks to be disabled when you refresh / start the experience
window.localStorage.removeItem('is-mocks-enabled')

if (REACT_APP_MAINTENANCE_MODE) {
  ReactDOM.render(
    <React.StrictMode>
      <MaintenancePage />
    </React.StrictMode>,
    document.getElementById('root'),
  )
} else {
  ReactDOM.render(
    <React.StrictMode>
      <HubspotProvider>
        <AnalyticsProvider>
          <StatusProvider>
            <TemplatesProvider>
              <EndpointProvider>
                <SettingsProvider>
                  <AuthenticationProvider>
                    <Router>
                      <Switch>
                        <Route path="/:param1?/:param2?">
                          <PlayPage />
                        </Route>
                      </Switch>
                    </Router>
                  </AuthenticationProvider>
                </SettingsProvider>
              </EndpointProvider>
            </TemplatesProvider>
          </StatusProvider>
        </AnalyticsProvider>
      </HubspotProvider>
    </React.StrictMode>,
    document.getElementById('root'),
  )
}
