export const COMBINATIONS_ENDPOINT = '/combinations'
export const ENDPOINT_ENDPOINT = '/endpoint'
export const QUERIES_ENDPOINT = '/queries'
export const SCHEMA_ENDPOINT = '/schema'
export const TEMPLATES_ENDPOINT = '/templates'

export const PAGE_PATHS = {
  list: '',
  addSource: 'add-source',
  publish: 'publish',
  tutorial: 'tutorial',
}
