import {useEffect, useState} from 'react'
import {parseCookies, setCookie} from 'nookies'

const {REACT_APP_STEPZEN_WEBSITE_DOMAIN} = process.env

function CookieBanner() {
  const [showCookiesBanner, setShowCookieBanner] = useState(false)
  const cookies = parseCookies()

  useEffect(() => {
    if (cookies.cookieAccepted) {
      setShowCookieBanner(false)
    } else {
      setShowCookieBanner(true)
    }
  }, [cookies.cookieAccepted])

  const handleCookieClick = evt => {
    evt.preventDefault()
    setCookie(null, 'cookieAccepted', 'true')
    setShowCookieBanner(false)
  }

  return showCookiesBanner ? (
    <div className="dialog cookie-banner" aria-modal="true">
      <p>
        This site uses cookies: By using this website, you consent to our use of
        cookies in accordance with our{' '}
        <a
          href={`${REACT_APP_STEPZEN_WEBSITE_DOMAIN}/website-terms-of-use/`}
          target="_blank"
          rel="noreferrer"
        >
          Website Terms of Use
        </a>{' '}
        and{' '}
        <a
          href={`${REACT_APP_STEPZEN_WEBSITE_DOMAIN}/cookies/`}
          target="_blank"
          rel="noreferrer"
        >
          Cookie Policy
        </a>
        .
      </p>
      <div className="dialog-actions">
        <button className="button secondary small" onClick={handleCookieClick}>
          <span>Close</span>
        </button>
      </div>
    </div>
  ) : null
}

export default CookieBanner
