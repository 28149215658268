import logo from '../../assets/images/mark.svg'

function Loading({text = 'Loading'}) {
  return (
    <div className="loading-overlay">
      <div>
        <img src={logo} alt="StepZen" className="logo" />
        <p>{text}...</p>
      </div>
    </div>
  )
}

export default Loading
