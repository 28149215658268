import {createContext, useState} from 'react'

import {getItem, setItem, YEAR} from '../utils/localStorage'

const statusContext = createContext()

const StatusProvider = props => {
  // key state
  const [hasAllKeys, setHasAllKeys] = useState(false)
  const [hasKey, setHasKey] = useState(false)
  // run state
  const [hasRun, setHasRun] = useState(false)
  // loading state
  const [isLoading, setIsLoading] = useState(false)
  // mocking state
  const mocked = getItem('is-mocks-enabled')
  const [isMocked, setIsMocked] = useState(mocked)
  const handleSetIsMocked = value => {
    setItem('is-mocks-enabled', value, YEAR)
    setIsMocked(value)
  }
  // tutorial state
  const tutorialHidden = getItem('is-tutorial-hidden')
  const [showTutorial, setShowTutorial] = useState(!tutorialHidden)
  const handleSetShowTutorial = value => {
    setItem('is-tutorial-hidden', !value, YEAR)
    setShowTutorial(value)
  }

  // combo details state
  const [comboDetailstoShow, setComboDetailstoShow] = useState(null)

  // mobile view state
  const [viewShown, setViewShown] = useState('build')

  const state = {
    comboDetailstoShow,
    hasAllKeys,
    hasKey,
    hasRun,
    isLoading,
    isMocked,
    viewShown,
    setComboDetailstoShow,
    setHasAllKeys,
    setHasKey,
    setHasRun,
    setIsLoading,
    setIsMocked: handleSetIsMocked,
    setShowTutorial: handleSetShowTutorial,
    showTutorial,
    setViewShown,
  }

  return (
    <statusContext.Provider value={state}>
      {props.children}
    </statusContext.Provider>
  )
}

export {statusContext, StatusProvider}
