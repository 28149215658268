import {Link} from 'react-router-dom'
import {useContext} from 'react'

import {statusContext} from '../../contexts/status'
import {endpointContext} from '../../contexts/endpoint'

import {getUrl} from '../../utils/url-helper'
import {PAGE_PATHS} from '../../utils/constants'

function AppNav() {
  const {selected} = useContext(endpointContext)
  const {viewShown, setViewShown} = useContext(statusContext)

  return (
    <nav className="app-nav">
      <ul>
        <li>
          <Link
            to={getUrl(PAGE_PATHS.list, selected)}
            className="button"
            aria-current={viewShown === 'build' ? 'page' : 'false'}
            aria-label="View a list of available schemas."
            onClick={() => {
              setViewShown('build')
            }}
          >
            <span>Build</span>
          </Link>
        </li>
        <li>
          <Link
            to={getUrl(PAGE_PATHS.list, selected)}
            className="button"
            aria-current={viewShown === 'run' ? 'page' : 'false'}
            onClick={() => {
              setViewShown('run')
            }}
          >
            <span>Run</span>
          </Link>
        </li>
        <li>
          <Link
            to={getUrl(PAGE_PATHS.list, selected)}
            className="button"
            aria-current={viewShown === 'results' ? 'page' : 'false'}
            onClick={() => {
              setViewShown('results')
            }}
          >
            <span>Results</span>
          </Link>
        </li>
        <li>
          <Link
            to={getUrl(PAGE_PATHS.publish, selected)}
            className="button"
            aria-current={viewShown === 'publish' ? 'page' : 'false'}
            aria-label="Publish your endpoint and use it in your app."
            onClick={() => {
              setViewShown('publish')
            }}
          >
            <span>Publish</span>
          </Link>
        </li>
      </ul>
    </nav>
  )
}

export default AppNav
