import {useContext} from 'react'

import {endpointContext} from '../../contexts/endpoint'

import icons from '../../assets/images/icons.svg'

function ComboAdd({combination}) {
  const {addSelected, selected} = useContext(endpointContext)

  return (
    <button
      className="card-action-button addSchema icon-button color4"
      onClick={() => addSelected(combination.includes)}
    >
      <span>
        {combination.includes.every(template =>
          selected.find(i => i.name === template),
        ) ? (
          <strong>Added</strong>
        ) : (
          <>
            <span className="combo-card-count">
              {combination.includes.length}
            </span>
            <svg className="icon addSchema-icon" aria-hidden="true">
              <use xlinkHref={`${icons}#plus`}></use>
            </svg>
          </>
        )}
      </span>
    </button>
  )
}

export default ComboAdd
