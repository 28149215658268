import {createGraphiQLFetcher} from '@graphiql/toolkit'
import GraphiQL from 'graphiql'
import {parse, visit} from 'graphql'
import {useContext, useEffect, useState} from 'react'

import Loading from '../Loading'

import Toolbar from './Toolbar'

import {analyticsContext} from '../../contexts/analytics'
import {endpointContext} from '../../contexts/endpoint'
import {settingsContext} from '../../contexts/settings'

import './GraphiQL.scss'

function GraphiQLComponent() {
  const {endpoint, queries, schema, selected} = useContext(endpointContext)
  const {userId} = useContext(analyticsContext)
  const {variables} = useContext(settingsContext)

  const [graphiQL, setGraphiQL] = useState(null)

  const [queryView, setQueryView] = useState(0)
  const [operations, setOperations] = useState([])
  const [resultsView, setResultsView] = useState(0)

  useEffect(() => {
    let ops = []

    if (queries?.queries) {
      const ast = parse(queries?.queries)
      visit(ast, {
        OperationDefinition(node) {
          ops.push(node)
        },
      })
    }

    setOperations(ops)
  }, [queries])

  const queryVariables = {}
  if (variables) {
    for (const vars of Object.values(variables)) {
      for (const [question, value] of Object.entries(vars)) {
        queryVariables[question] =
          window.localStorage.getItem(question) || value
      }
    }
  }

  if (endpoint === null) {
    return null
  }

  if (!endpoint && selected.length === 0) {
    return null
  }

  const fetcher = createGraphiQLFetcher({
    url: endpoint.url,
    headers: {
      'Stepzen-Analytics-ID': userId,
    },
  })

  const handleEditQuery = content => {
    try {
      let ops = []
      const ast = parse(content)
      visit(ast, {
        OperationDefinition(node) {
          ops.push(node)
        },
      })
      setOperations(ops)
    } catch {}
  }

  return (
    <div
      className={`graphiql-wrapper ${queryView === 1 ? 'schema-showing' : ''}`}
    >
      {endpoint?.deployed ? (
        <>
          <GraphiQL
            fetcher={fetcher}
            query={queries?.queries || ''}
            onEditQuery={handleEditQuery}
            ref={ref => setGraphiQL(ref)}
            variables={JSON.stringify(queryVariables, null, 2)}
          >
            <GraphiQL.Toolbar>
              <Toolbar
                graphiql={graphiQL}
                queryView={queryView}
                operations={operations}
                resultsView={resultsView}
                setQueryView={setQueryView}
                setResultsView={setResultsView}
              />
            </GraphiQL.Toolbar>
          </GraphiQL>
          {queryView === 1 ? (
            <div className="graphiql-schema-view">
              <div className="scrollable">
                <pre className="language-graphql">
                  <code
                    className="language-graphql"
                    dangerouslySetInnerHTML={{
                      __html: schema?.schema || '',
                    }}
                  />
                </pre>
              </div>
            </div>
          ) : null}
        </>
      ) : (
        <Loading text={endpoint?.message || 'Loading editor'} />
      )}
    </div>
  )
}

export default GraphiQLComponent
