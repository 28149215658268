import {useContext} from 'react'

import {authenticationContext} from '../../contexts/authentication'

function SignUpBanner() {
  const {loginFn, loginUrl} = useContext(authenticationContext)

  return (
    <div className="signUpBanner">
      <h2 className="signUpBanner-headline">
        Create an account to get
        <br />
        the full benefits of StepZen
      </h2>
      <div className="highlights-list">
        <ul>
          <li>
            <strong>A simpler way to build a GraphQL API</strong>
            Easily connect and stitch data sources without extensive coding
            using powerful custom directives like @rest, @materializer, and
            @sequence.
          </li>
          <li>
            <strong>A fully managed service</strong>
            Enjoy scaled and secured API deployments and backends. StepZen
            manages the infrastructure.
          </li>
          <li>
            <strong>Generous free tier for developers</strong>
            Your API, no credit card required.
          </li>
        </ul>
      </div>
      <div className="signUpBanner-actions">
        <a
          className="button color9 secondary"
          href={loginUrl}
          onClick={loginFn}
          data-analytics-action="createAccount"
          data-analytics-data={loginUrl}
        >
          <span>Create account</span>
        </a>
      </div>
    </div>
  )
}

export default SignUpBanner
