import {useState} from 'react'

import icons from '../../../assets/images/icons.svg'

import './TextInput.scss'

function TextInput({id, name, onChange, type, value}) {
  const [visible, setVisible] = useState(false)

  const canToggleView = type === 'password'

  const handleOnToggleVisible = () => {
    setVisible(!visible)
  }

  return (
    <div className={`textinput-wrapper ${canToggleView ? 'has-toggle' : ''}`}>
      <input
        autoComplete="off"
        defaultValue={value}
        id={id}
        name={name}
        onInput={onChange}
        type={visible ? 'text' : type}
      />
      {canToggleView ? (
        <div className="toggle">
          <svg
            aria-hidden="true"
            className="icon"
            onClick={handleOnToggleVisible}
          >
            {visible ? (
              <use xlinkHref={`${icons}#eye-open`}></use>
            ) : (
              <use xlinkHref={`${icons}#eye-closed`}></use>
            )}
          </svg>
        </div>
      ) : null}
    </div>
  )
}

export default TextInput
