import Tippy from '@tippyjs/react'
import {useContext, useEffect} from 'react'
import {useParams} from 'react-router-dom'

import AddSource from '../AddSource'
import AddSourceBanner from '../AddSourceBanner'
import CookieBanner from '../CookieBanner'
import ComboDetails from '../ComboDetails'
import ExamplePrompt from '../ExamplePrompt'
import GraphiQL from '../GraphiQL'
import Publish from '../Publish'
import SelectedTemplatesList from '../SelectedTemplatesList'
import TemplateList from '../TemplateList'
import TemplateSettings from '../TemplateSettings'
import Tutorial from '../Tutorial'

import {endpointContext} from '../../contexts/endpoint'
import {settingsContext} from '../../contexts/settings'
import {statusContext} from '../../contexts/status'

import {
  isAddSource,
  isListPage,
  isPublishPage,
  isTutorialPage,
} from '../../utils/url-helper'

import clickit from '../../assets/images/graphics/clickit.svg'
import icons from '../../assets/images/icons.svg'
import run from '../../assets/images/graphics/run.svg'

function Play() {
  const {editing} = useContext(settingsContext)
  const {selected} = useContext(endpointContext)
  const {comboDetailstoShow, isMocked, setIsMocked} = useContext(statusContext)

  const params = useParams()

  const handleToggleMocks = evt => {
    const {checked} = evt.target
    setIsMocked(checked)
  }

  useEffect(() => {
    if (isListPage(params)) {
      document.body.classList.add('no-scroll')
    }

    return () => {
      document.body.classList.remove('no-scroll')
    }
  }, [params])

  return (
    <>
      {isListPage(params) ? (
        <div className="app-studio">
          {isListPage(params) ? (
            <div className="app-control">
              <div className="col-wrapper">
                <div className="selectedList">
                  {selected.length === 0 ? (
                    <ExamplePrompt />
                  ) : (
                    <>
                      <header className="selectedHeader">
                        <h2 className="selectedHeader-headline">
                          Selected schemas
                        </h2>
                        <div className="toggle field">
                          <input
                            defaultChecked={isMocked}
                            className="sr-only"
                            id="MockDataAcknowledge"
                            onChange={handleToggleMocks}
                            type="checkbox"
                            value="true"
                          />
                          <label
                            htmlFor="MockDataAcknowledge"
                            data-analytics-action="mock"
                            data-analytics-data="Use mock data"
                          >
                            Use mock data
                          </label>
                          {/* !!! - Not accessible */}
                          {isMocked ? (
                            <Tippy content="StepZen will show realistic results, but mocked data.">
                              <svg className="icon" aria-hidden="true">
                                <use xlinkHref={`${icons}#help`}></use>
                              </svg>
                            </Tippy>
                          ) : (
                            <Tippy content="Enable to use mock data for APIs that require key authorization. StepZen will show realistic results using mocked data.">
                              <svg className="icon" aria-hidden="true">
                                <use xlinkHref={`${icons}#help`}></use>
                              </svg>
                            </Tippy>
                          )}
                        </div>
                      </header>
                      <div className="scrollable">
                        <SelectedTemplatesList />
                      </div>
                    </>
                  )}
                </div>

                <div className="templateList">
                  <TemplateList />
                  {editing ? <TemplateSettings /> : null}
                </div>
                <AddSourceBanner />
              </div>
            </div>
          ) : null}

          {isListPage(params) ? (
            <>
              {selected.length > 0 ? (
                <GraphiQL />
              ) : (
                <div className="graphiql-wrapper">
                  <ol className="miniTutorial">
                    <li className="miniTutorial-step">
                      <img
                        src={clickit}
                        alt="A circle containing a plus symbol. A cursor is hovering over the circle."
                        className="graphic"
                        height="88"
                      />
                      Add schemas to your GraphQL API
                    </li>

                    <li className="miniTutorial-step">
                      <img
                        src={run}
                        alt="A button containing the word 'Run' and play icon."
                        className="graphic"
                        height="70"
                      />
                      Run queries to see responses
                    </li>
                  </ol>
                </div>
              )}

              <div className="app-notifications">
                <CookieBanner />
              </div>
            </>
          ) : null}
        </div>
      ) : null}

      {comboDetailstoShow ? <ComboDetails /> : null}

      {isAddSource(params) ? <AddSource /> : null}
      {isPublishPage(params) ? <Publish /> : null}
      {isTutorialPage(params) ? <Tutorial /> : null}
    </>
  )
}

export default Play
