import {useContext} from 'react'

import {endpointContext} from '../../contexts/endpoint'

import icons from '../../assets/images/icons.svg'

function EndpointField() {
  const {endpoint} = useContext(endpointContext)

  const handleOnCopy = () => {
    navigator.clipboard.writeText(endpoint?.url)
  }

  return (
    <form className="endpointForm">
      <div className="field copy-field inline-label">
        <label htmlFor="endpoint-url">Endpoint</label>
        <input
          disabled={!endpoint?.url}
          id="endpoint-url"
          readOnly
          type="text"
          value={endpoint?.url || ''}
        />
        <button
          aria-label="Copy"
          className="button copy-code"
          disabled={!endpoint?.url}
          onClick={handleOnCopy}
          type="button"
        >
          <svg aria-hidden="true" className="icon">
            <use xlinkHref={`${icons}#copy`}></use>
          </svg>
        </button>
      </div>
    </form>
  )
}

export default EndpointField
