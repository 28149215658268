import {useContext} from 'react'

import {endpointContext} from '../../../contexts/endpoint'
import {templatesContext} from '../../../contexts/templates'

import ComboCard from '../../ComboCard'

function Combinations() {
  const {isSelected} = useContext(endpointContext)
  const {combinations} = useContext(templatesContext)

  const allSelected = templates =>
    templates.every(template => isSelected(template))

  return (
    <div id="TemplateListCombinations">
      <h2 className="sr-only">All Combinations</h2>
      <ul className="object-list">
        {combinations.map(combination =>
          allSelected(combination.includes) ? null : (
            <li key={`combo-item-${combination.name}`}>
              <ComboCard combination={combination} />
            </li>
          ),
        )}
      </ul>
    </div>
  )
}

export default Combinations
