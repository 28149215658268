import {useContext, useEffect, useState} from 'react'

import {endpointContext} from '../../contexts/endpoint'

import {getItem, MONTH, setItem} from '../../utils/localStorage'

function ExamplePrompt({expires = 3 * MONTH}) {
  const {addSelected} = useContext(endpointContext)

  const [showExampleDialog, setShowExampleDialog] = useState(false)

  useEffect(() => {
    const state = getItem('hide-example-dialog')

    if (state?.hidden) {
      setShowExampleDialog(false)
    } else {
      setShowExampleDialog(true)
    }
  }, [])

  const handleOnCloseDialog = evt => {
    evt.preventDefault()

    setShowExampleDialog(false)

    setItem(
      'hide-example-dialog',
      {
        hidden: true,
      },
      expires,
    )
  }

  const handleOnShowExample = evt => {
    evt.preventDefault()

    addSelected(['frankfurter', 'ip-api'])
  }

  return (
    <>
      {showExampleDialog ? (
        <div className="application-prompts example-prompt">
          <h2 className="prompt-headline">Welcome to GraphQL Studio</h2>
          <p>
            Build, explore and deploy your own GraphQL API with just a few
            clicks. No resolvers to write. No data connections to code. No
            servers to build.
          </p>
          <p>
            <strong>Would you like to see how?</strong>
          </p>

          <div className="button-group">
            <button
              className="button color16"
              data-analytics-action="showExample"
              data-analytics-data="Show an example"
              onClick={handleOnShowExample}
            >
              <span>Show an example</span>
            </button>
            <button
              className="button linkButton colorWhite"
              data-analytics-action={'hideExample'}
              data-analytics-data={'No to example'}
              onClick={handleOnCloseDialog}
              type="button"
            >
              <span>No, thanks</span>
            </button>
          </div>
        </div>
      ) : null}
    </>
  )
}

export default ExamplePrompt
