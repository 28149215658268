import {useEffect, useState} from 'react'
import {sectionsContent} from './sectionsContent'

import {getItem, setItem} from '../../utils/localStorage'

function Tutorial({onCloseTutorial}) {
  const [sectionShown, setSectionShown] = useState(0)

  function DismissButton() {
    return (
      <button
        data-analytics-action="TutorialDismissed"
        data-analytics-data="lets build"
        onClick={onCloseTutorial}
        className="button small color16"
      >
        <span>{"Got it, let's build!"}</span>
      </button>
    )
  }

  function TutorialSection({section}) {
    const [highlightShown, setHighlightShown] = useState(0)

    const media = section.highlights[highlightShown].media

    function showHighlight(e, highlightNum) {
      e.preventDefault()

      setHighlightShown(highlightNum)

      setItem(`tutorial-s${sectionShown}-h${highlightNum}`, true, 0)
    }

    useEffect(() => {
      setItem(`tutorial-s${sectionShown}-h0`, true, 0)
    })

    return (
      <div className="tutorialSection">
        <header className="tutorialSection-header">
          <div className="tutorialSection-header-media">
            <img
              src={process.env.PUBLIC_URL + section.icon.src}
              alt={section.icon.alt}
              height="64"
            />
          </div>
          <div className="tutorialSection-header-content">
            <h1 className="tutorialSection-headline">{section.headline}</h1>
            <p>{section.description}</p>
            <DismissButton />
          </div>
        </header>

        <div className="tutorialSection-contents">
          <div className="tutorialSection-highlights">
            {section.highlights.map((highlight, index) => {
              const visibleStatus =
                index === 0
                  ? true
                  : getItem(`tutorial-s${sectionShown}-h${index}`)

              return (
                <details
                  className="tutorialHighlight"
                  data-analytics-action="tutorialOption"
                  data-analytics-data={highlight.headline}
                  data-completed={visibleStatus}
                  open={highlightShown === index}
                  onClick={e => {
                    showHighlight(e, index)
                  }}
                  key={`Highlight-${index}`}
                >
                  <summary className="cover">
                    <h2>{highlight.headline}</h2>
                  </summary>
                  <div className="tutorialSection-highlightContent">
                    {highlight.description}
                  </div>
                </details>
              )
            })}
          </div>

          <div className={`tutorialSection-media type-${media.type}`}>
            {media.src && media.type === 'video' ? (
              <div className="video-embed">
                <video
                  data-analytics-action="watchTutorial"
                  data-analytics-data={media?.poster}
                  controls
                  preload="metadata"
                  width="632"
                  poster={media?.poster}
                >
                  <source src={media.src} type="video/mp4" />
                  Sorry, your browser doesn't support embedded videos.
                </video>
              </div>
            ) : (
              <img src={media.src} alt={media.alt} />
            )}

            <small className="legal-disclaimer">
              All logos and trademarks are the property of their respective
              owners
            </small>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="tutorial">
      <TutorialSection section={sectionsContent[sectionShown]} />

      <div className="tutorial-navWrap">
        <nav className="tutorial-sectionNav">
          <ul className="dotNav" aria-label="Navigate to different sections">
            {sectionsContent.map((section, index) => {
              return (
                <li
                  aria-current={sectionShown === index}
                  key={`DotNav-${index}`}
                >
                  <button
                    aria-label={`Go to: ${section.headline}`}
                    onClick={() => setSectionShown(index)}
                  >
                    <span>{index}</span>
                  </button>
                </li>
              )
            })}
          </ul>

          {sectionShown < sectionsContent.length - 1 ? (
            <button
              className="button color16"
              onClick={() => setSectionShown(sectionShown + 1)}
            >
              <span className="has-rarrow">Next</span>
            </button>
          ) : (
            <button
              className="button color16"
              data-analytics-action="tutorialWalked"
              data-analytics-data="Build with Studio"
              onClick={onCloseTutorial}
            >
              <span>Build with Studio</span>
            </button>
          )}
        </nav>
      </div>
    </div>
  )
}

export default Tutorial
