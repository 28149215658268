import {useContext, useEffect} from 'react'

import Layout from '../../components/Layout'
import Loading from '../../components/Loading'
import Play from '../../components/Play'
import Tutorial from '../../components/Tutorial'

import {analyticsContext} from '../../contexts/analytics'
import {statusContext} from '../../contexts/status'
import {templatesContext} from '../../contexts/templates'

const PlayPage = () => {
  const {error, loading} = useContext(templatesContext)
  const {pageview} = useContext(analyticsContext)
  const {setShowTutorial, showTutorial} = useContext(statusContext)

  const url = window.location.pathname

  useEffect(() => {
    pageview(url)
  }, [url])

  if (error) {
    return <Layout>{JSON.stringify(error, null, 2)}</Layout>
  }

  if (loading) {
    return <Loading />
  }

  const handleOnCloseTutorial = evt => {
    setShowTutorial(false)
  }

  return (
    <Layout>
      {showTutorial ? (
        <div className="app-overlay tutorial-overlay">
          <div className="col-wrapper tutorial-overlay-wrapper">
            <Tutorial onCloseTutorial={handleOnCloseTutorial} />
          </div>
        </div>
      ) : null}
      <Play />
    </Layout>
  )
}

export default PlayPage
