import {createContext, useContext, useEffect, useState} from 'react'
import orderBy from 'lodash.orderby'
import parserGraphql from 'prettier/parser-graphql'
import prettier from 'prettier/standalone'
import uniq from 'lodash.uniq'

import {statusContext} from './status'
import {templatesContext} from './templates'

import {
  ENDPOINT_ENDPOINT,
  QUERIES_ENDPOINT,
  SCHEMA_ENDPOINT,
} from '../utils/constants'
import {updateUrl} from '../utils/url-helper'

const {REACT_APP_SERVER_URL} = process.env

const endpointContext = createContext()

const EndpointProvider = props => {
  const {isMocked, setIsMocked} = useContext(statusContext)
  const {selected, setSelected, templates} = useContext(templatesContext)

  const [endpoint, setEndpoint] = useState(null)
  const [endpointLoading, setEndpointLoading] = useState(false)
  const [error, setError] = useState(null)
  const [queries, setQueries] = useState(null)
  const [queriesLoading, setQueriesLoading] = useState(false)
  const [schema, setSchema] = useState(null)
  const [schemaLoading, setSchemaLoading] = useState(false)

  const [endpointReqController, setEndpointReqController] = useState(null)
  const [queriesReqController, setQueriesReqController] = useState(null)
  const [schemaReqController, setSchemaReqController] = useState(null)

  useEffect(() => {
    setError(null)

    if (selected.length === 0) {
      setEndpoint(null)
      setQueries(null)
      setSchema(null)
      return
    }

    const params = {
      templates: selected.map(t => t.name).join(','),
      mocked: isMocked ? selected.map(t => t.name).join(',') : '',
    }

    // ENDPOINTS
    ;(async () => {
      try {
        setEndpointLoading(true)

        if (endpointReqController) {
          endpointReqController.abort()
        }
        const controller = new AbortController()
        setEndpointReqController(controller)

        const url = new URL(`${REACT_APP_SERVER_URL}${ENDPOINT_ENDPOINT}`)
        url.search = new URLSearchParams(params).toString()

        const response = await fetch(url, {
          headers: {
            'cache-control': 'no-cache',
            pragma: 'no-cache',
          },
          signal: controller.signal,
        })

        const decoder = new TextDecoder()
        const reader = response.body.getReader()

        while (true) {
          const {value, done} = await reader.read()
          if (done) break
          const decoded = decoder.decode(value)
          const last = decoded.split('{').slice(-1)
          const json = JSON.parse(`{${last}`)
          setEndpoint(json)
        }

        setEndpointLoading(false)
      } catch (err) {
        setEndpoint(null)
        setEndpointLoading(false)
        setError('An error occurred')
        console.log(err)
      }
    })()

    // QUERIES
    ;(async () => {
      try {
        setQueriesLoading(true)

        if (queriesReqController) {
          queriesReqController.abort()
        }
        const controller = new AbortController()
        setQueriesReqController(controller)

        const url = new URL(`${REACT_APP_SERVER_URL}${QUERIES_ENDPOINT}`)
        url.search = new URLSearchParams(params).toString()

        const response = await fetch(url, {
          headers: {
            'cache-control': 'no-cache',
            pragma: 'no-cache',
          },
          signal: controller.signal,
        })
        const json = await response.json()

        setQueries(json)
        setQueriesLoading(false)
      } catch (err) {
        setError('An error occurred')
        setQueries(null)
        setQueriesLoading(false)
        console.log(err)
      }
    })()

    // SCHEMA
    ;(async () => {
      try {
        setSchemaLoading(true)

        if (schemaReqController) {
          schemaReqController.abort()
        }
        const controller = new AbortController()
        setSchemaReqController(controller)

        const url = new URL(`${REACT_APP_SERVER_URL}${SCHEMA_ENDPOINT}`)
        url.search = new URLSearchParams(params).toString()

        const response = await fetch(url, {
          headers: {
            'cache-control': 'no-cache',
            pragma: 'no-cache',
          },
          signal: controller.signal,
        })
        const json = await response.json()

        setSchema(json)
        setSchemaLoading(false)
      } catch (err) {
        setError('An error occurred')
        setSchema(null)
        setSchemaLoading(false)
        console.log(err)
      }
    })()
  }, [isMocked, selected])

  const addSelected = template => {
    const adding = templates.filter(t => {
      if (Array.isArray(template)) {
        return template.includes(t.name)
      }

      return t.name === template
    })
    const updated = [...selected, ...adding]
    let deduped = uniq(updated)
    deduped.sort()

    setEndpoint({})
    setSelected(deduped)
    updateUrl(deduped)
  }

  const isSelected = template => {
    const found = selected.find(t => t.name === template)
    return !!found
  }

  const removeSelected = template => {
    const updated = selected.filter(t => t.name !== template)
    let deduped = uniq(updated)
    deduped.sort()

    setEndpoint({})
    setSelected(updated)
    updateUrl(deduped)
  }

  const sorted = orderBy(selected, template => template.name)

  // This allows you to pass an extra query in the querystring
  // ?query={foo { bar }}
  const getQueries = queries => {
    const urlParams = new URLSearchParams(window.location.search)
    const qsQuery = urlParams.get('query')

    try {
      if (qsQuery && queries?.queries) {
        const cloned = {...queries}

        cloned.queries = `query MyQuery {\n${qsQuery}\n}\n\n${cloned.queries}`
        cloned.queries = prettier.format(cloned.queries, {
          parser: 'graphql',
          plugins: [parserGraphql],
          printWidth: 50,
        })

        return cloned
      }
    } catch {
      console.log(`Discarding invalid GraphQL query from URL:\n\n${qsQuery}`)
    }

    return queries
  }

  const state = {
    endpoint,
    queries: getQueries(queries),
    schema,
    isMocked,
    setEndpoint,
    setIsMocked,
    addSelected,
    isSelected,
    removeSelected,
    selected: sorted,
  }

  return (
    <endpointContext.Provider value={state}>
      {props.children}
    </endpointContext.Provider>
  )
}

export {endpointContext, EndpointProvider}
