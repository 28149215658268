import {createContext, useContext, useEffect, useState} from 'react'

import {endpointContext} from './endpoint'

const authenticationContext = createContext()

const {REACT_APP_STEPZEN_WEBSITE_DOMAIN} = process.env

const AuthenticationProvider = props => {
  const {selected} = useContext(endpointContext)
  const [user, setUser] = useState(null)

  const origin = window.location.origin
  const redirectUri = `${origin}/${selected.map(s => s.name).join(',')}`

  useEffect(() => {
    ;(async () => {
      try {
        const response = await fetch(
          `${REACT_APP_STEPZEN_WEBSITE_DOMAIN}/api/auth/session`,
          {
            credentials: 'include',
          },
        )
        const json = await response.json()

        setUser(json)
      } catch {}
    })()
  }, [])

  const loginUrl = new URL(`${REACT_APP_STEPZEN_WEBSITE_DOMAIN}/auth`)
  loginUrl.searchParams.append('screen_hint', 'login')
  loginUrl.searchParams.append('utm_source', window.location.origin)
  loginUrl.searchParams.append('utm_medium', 'studio')
  loginUrl.searchParams.append('callbackUrl', redirectUri)

  const logoutUrl = new URL(`${REACT_APP_STEPZEN_WEBSITE_DOMAIN}/auth`)
  logoutUrl.searchParams.append('screen_hint', 'logout')
  logoutUrl.searchParams.append('utm_source', window.location.origin)
  logoutUrl.searchParams.append('utm_medium', 'studio')
  logoutUrl.searchParams.append('callbackUrl', redirectUri)

  const loginFn = evt => {
    evt.preventDefault()

    window.location.href = loginUrl
  }

  const state = {
    loginUrl,
    loginFn,
    logoutUrl,
    user,
  }

  return (
    <authenticationContext.Provider value={state}>
      {props.children}
    </authenticationContext.Provider>
  )
}

export {authenticationContext, AuthenticationProvider}
