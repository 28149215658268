import Tippy from '@tippyjs/react'
import {useContext, useEffect, useState} from 'react'

import {endpointContext} from '../../../contexts/endpoint'
import {statusContext} from '../../../contexts/status'

import icons from '../../../assets/images/icons.svg'

const DOCS_VISIBLE = {
  false: 0,
  true: 1,
}

function GraphiQLToolbar({
  graphiql,
  queryView,
  operations,
  resultsView,
  setQueryView,
  setResultsView,
}) {
  const {queries, schema} = useContext(endpointContext)
  const {hasRun, setHasRun} = useContext(statusContext)

  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [copyMessage, setCopyMessage] = useState(
    `Click to copy the ${
      queryView === 0 ? 'sample queries' : 'schema'
    } to your clipboard`,
  )
  const [copyResult, setCopyResult] = useState(
    `Click to copy the response to your clipboard`,
  )

  useEffect(() => {
    const handler = evt => {
      if (evt?.target) {
        const dropdown = evt?.target.closest('.run-container')
        if (!dropdown) {
          setDropdownOpen(false)
        }
      }
    }
    document.body.addEventListener('click', handler)
    return () => {
      document.body.removeEventListener('click', handler)
    }
  }, [])

  const handleOnRun = (evt, name) => {
    evt.preventDefault()

    setDropdownOpen(false)
    setHasRun(true)
    setResultsView(0)
    toggleDocsExplorer(DOCS_VISIBLE.false)

    graphiql.handleRunQuery(name)
  }

  const handleToggleDropdown = () => {
    const state = !dropdownOpen
    setDropdownOpen(state)
  }

  const handleTipMessage = what => {
    if (what === 'result') {
      setCopyResult('')
      setTimeout(function () {
        setCopyResult(`Click here to copy the result to your clipboard`)
      }, 5000)
    }

    if (what === 'code') {
      setCopyMessage('')
      setTimeout(function () {
        setCopyMessage(
          `Click here to copy the ${
            queryView === 0 ? 'sample queries' : 'schema'
          } to your clipboard`,
        )
      }, 5000)
    }
  }

  const handleOnCopy = (evt, what) => {
    // Change the Tip Message
    handleTipMessage(what)

    if (what === 'code' && queryView === 0) {
      navigator.clipboard.writeText(queries.queries)
    }
    if (what === 'code' && queryView === 1) {
      navigator.clipboard.writeText(schema.schema)
    }
    if (what === 'result') {
      navigator.clipboard.writeText(graphiql.state.response)
    }
  }

  const toggleDocsExplorer = state => {
    // The docs are hidden, and we want to show them
    if (
      graphiql.state.docExplorerOpen === false &&
      state === DOCS_VISIBLE.true
    ) {
      graphiql.handleToggleDocs()
    }
    // The docs are visible, and we want to hide them
    if (
      graphiql.state.docExplorerOpen === true &&
      state === DOCS_VISIBLE.false
    ) {
      graphiql.handleToggleDocs()
    }
  }

  return (
    <div className="graphiql-toolbar">
      <div className="column">
        <div role="tablist" className="tabs">
          <button
            aria-controls="ControlPane1"
            aria-selected={queryView === 0}
            className="button tab"
            id="ControlPane1Trigger"
            onClick={() => {
              setQueryView(0)
              setCopyMessage(
                'Click here to copy the sample queries to your clipboard',
              )
            }}
            role="tab"
          >
            <span>Query</span>
          </button>
          <button
            aria-controls="ControlPane2"
            aria-selected={queryView === 1}
            className="button tab"
            id="ControlPane2Trigger"
            onClick={() => {
              setQueryView(1)
              setCopyMessage('Click here to copy the schema to your clipboard')
            }}
            role="tab"
          >
            <span>Schema</span>
          </button>

          <div className="run-container">
            <button
              className="button secondary ml-auto run-button"
              onClick={evt => {
                operations?.length > 1
                  ? handleToggleDropdown(evt)
                  : handleOnRun(evt)
              }}
            >
              <span>
                <svg aria-hidden="true" className="icon">
                  <use xlinkHref={`${icons}#play`}></use>
                </svg>
                Run{' '}
                {operations?.length > 1 ? (
                  <svg aria-hidden="true" className="icon moreIcon">
                    <use xlinkHref={`${icons}#more`}></use>
                  </svg>
                ) : null}
              </span>
            </button>
            {operations?.length > 1 && dropdownOpen ? (
              <div className="run-dropdown">
                <ul>
                  {operations.map((op, index) => (
                    <li key={`${op?.name?.value || 'unknown'}-${index}`}>
                      <button
                        disabled={!op?.name?.value}
                        onClick={evt => handleOnRun(evt, op?.name?.value)}
                        type="button"
                        data-analytics-action="query"
                        data-analytics-data={`${op?.name?.value}`}
                      >
                        {op?.name?.value || 'Unnamed Query'}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
          </div>
          <Tippy
            content={copyMessage ? copyMessage : 'Copied!'}
            hideOnClick={false}
          >
            <button
              aria-label="Copy"
              className="button copy-code"
              onClick={evt => handleOnCopy(evt, 'code')}
            >
              <svg aria-hidden="true" className="icon">
                <use xlinkHref={`${icons}#copy`}></use>
              </svg>
            </button>
          </Tippy>
        </div>
      </div>
      <div className="column">
        <div className="col-wrapper">
          <div role="tablist" className="tabs">
            <button
              className="button tab"
              role="tab"
              aria-selected={resultsView === 0}
              aria-controls="ControlPane1"
              id="ControlPane1Trigger"
              onClick={() => {
                setResultsView(0)
                toggleDocsExplorer(DOCS_VISIBLE.false)
              }}
            >
              <span>Response</span>
            </button>
            <button
              className="button tab"
              role="tab"
              aria-selected={resultsView === 1}
              aria-controls="ControlPane1"
              id="ControlPane1Trigger"
              onClick={() => {
                setResultsView(1)
                toggleDocsExplorer(DOCS_VISIBLE.true)
              }}
            >
              <span>Documentation</span>
            </button>
            {resultsView === 0 && hasRun ? (
              <Tippy
                content={copyResult ? copyResult : `Copied!`}
                hideOnClick={false}
              >
                <button
                  aria-label="Copy"
                  className="button copy-code ml-auto"
                  onClick={evt => handleOnCopy(evt, 'result')}
                >
                  <svg className="icon" aria-hidden="true">
                    <use xlinkHref={`${icons}#copy`}></use>
                  </svg>
                </button>
              </Tippy>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  )
}

export default GraphiQLToolbar
