import {useContext, useEffect} from 'react'
import {useHistory, useParams} from 'react-router-dom'

import {endpointContext} from '../../contexts/endpoint'
import {settingsContext} from '../../contexts/settings'
import {statusContext} from '../../contexts/status'

import backgroundSwatch from '../../utils/background-swatch'
import icons from '../../assets/images/icons.svg'

function SelectedTempatesList() {
  const {isEditing, setEditing} = useContext(settingsContext)
  const {isMocked, setHasAllKeys, setHasKey} = useContext(statusContext)
  const {removeSelected, selected} = useContext(endpointContext)

  const hasAllAnswers = questions => {
    if (!questions) {
      return true
    }

    for (const question of questions) {
      const key = question.name.split('.')[1]
      const val = window.localStorage.getItem(key)

      if (!val) return false
    }

    return true
  }

  const hasQuestions = questions => questions?.length > 0

  useEffect(() => {
    const allAnswersAnswered = selected.every(template =>
      hasAllAnswers(template.config?.questions),
    )
    const someAnswersAnswered = selected.some(template =>
      hasAllAnswers(template.config?.questions),
    )
    setHasAllKeys(allAnswersAnswered)
    setHasKey(someAnswersAnswered)
  }, [selected, setHasAllKeys, setHasKey])

  const handleOnRemoveTemplate = template => {
    removeSelected(template)
  }

  const checkAllKeys = () =>
    selected.every(template => hasAllAnswers(template.config?.questions))

  return (
    <>
      {!checkAllKeys() && !isMocked && (
        <p className="alert">
          <span>
            Some schemas <strong className="color10">need configuration</strong>
          </span>
        </p>
      )}
      <ul className="object-list">
        {selected.map(template => (
          <article
            className="card schema-card"
            key={`selected-item-${template.name}`}
          >
            <div className="card-media">
              <span
                className="schema-card-icon"
                style={{
                  backgroundImage: backgroundSwatch(template.metadata.colors),
                }}
              ></span>
            </div>
            <header className="card-header">
              <h3 className="card-title">{template.metadata.name}</h3>
            </header>

            {hasQuestions(template.config?.questions) &&
            hasAllAnswers(template.config?.questions) &&
            !isMocked ? (
              <button
                className="config-status configured"
                disabled={isMocked}
                onClick={() => setEditing(template.name)}
              >
                <span>{isEditing(template.name) ? '...' : 'Configure'}</span>
              </button>
            ) : null}

            {hasQuestions(template.config?.questions) &&
            hasAllAnswers(template.config?.questions) === false &&
            !isMocked ? (
              <button
                className="link config-status needs-config"
                disabled={isMocked}
                onClick={() => setEditing(template.name)}
                data-analytics-action="needsConfig"
                data-analytics-data={template.metadata.name}
              >
                <span>
                  <svg className="icon color10" aria-hidden="true">
                    <use xlinkHref={`${icons}#alert`}></use>
                  </svg>
                  {isEditing(template.name) ? '...' : 'Configure'}
                </span>
              </button>
            ) : null}

            <button
              className="card-action-button icon-button color4"
              aria-label={
                'Remove ' + template.metadata.name + ' from your endpoint.'
              }
              style={{border: 0, marginLeft: 10}}
              onClick={() => {
                setEditing(null)
                handleOnRemoveTemplate(template.name)
              }}
              data-analytics-action="remove"
              data-analytics-data={`${template.metadata.name}`}
            >
              <span>
                <svg className="icon small" aria-hidden="true">
                  <use xlinkHref={`${icons}#minus`}></use>
                </svg>
              </span>
            </button>
          </article>
        ))}
      </ul>
    </>
  )
}

export default SelectedTempatesList
