export const SECOND = 1000
export const MINUTE = 60 * SECOND
export const HOUR = 60 * MINUTE
export const DAY = 24 * HOUR
export const MONTH = 30 * DAY
export const YEAR = 365 * DAY
export const FOREVER = 10 * YEAR

export const getItem = key => {
  const content = localStorage.getItem(key)

  if (!content) {
    return null
  }

  const item = JSON.parse(content)
  const now = new Date()

  if (now.getTime() > item.expiry) {
    localStorage.removeItem(key)
    return null
  }

  return item.value
}

export const setItem = (key, value, expiry = 1 * DAY) => {
  const now = new Date()
  const ttl = expiry === 0 ? FOREVER : expiry

  const item = {
    value,
    expiry: now.getTime() + ttl,
  }

  localStorage.setItem(key, JSON.stringify(item))
}
