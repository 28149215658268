import {createContext, useContext, useState} from 'react'

import {endpointContext} from './endpoint'
import {templatesContext} from './templates'

const settingsContext = createContext()

const SettingsProvider = props => {
  const [editing, setEditing] = useState(null)

  const {queries} = useContext(endpointContext)
  const {templates} = useContext(templatesContext)

  const isEditing = template => {
    return editing === template
  }

  const template = templates.find(template => template.name === editing)
  const questions = template?.config?.questions

  const variables = queries?.variables

  const state = {
    editing,
    isEditing,
    questions,
    setEditing,
    template,
    variables,
  }

  return (
    <settingsContext.Provider value={state}>
      {props.children}
    </settingsContext.Provider>
  )
}

export {settingsContext, SettingsProvider}
