import {useContext} from 'react'

import {statusContext} from '../../contexts/status'

import ComboAdd from '../ComboAdd'

import communityBadge from '../../assets/images/icons/community.svg'

function ComboCard({combination}) {
  const {setComboDetailstoShow} = useContext(statusContext)

  return (
    <article
      className={`card schema-card combo-card ${
        combination.isUserSubmitted ? 'has-meta' : ''
      }`}
      data-analytics-action="combo"
      data-analytics-data={combination.name}
    >
      <div className="card-media"></div>
      <header className="card-header">
        <h3 className="card-title">{combination.name}</h3>
        <p className="sr-only">{combination.description}</p>

        <p>{combination.includes.join(' + ')}</p>
      </header>

      {combination.isUserSubmitted && (
        <button
          className="button"
          onClick={() => setComboDetailstoShow(combination)}
          aria-label="Click for more information about this community combination"
        >
          <img src={communityBadge} alt="" aria-hidden="true" height="40" />
        </button>
      )}

      <ComboAdd combination={combination} />

      {combination.isUserSubmitted && (
        <div className="combo-card-meta">
          <span
            className="combo-card-author"
            aria-label={`This combination was submitted by ${combination.author.name}`}
          >
            {combination.author.name}
          </span>
          <button
            className="button linkButton combo-card-infoButton"
            onClick={() => setComboDetailstoShow(combination)}
            aria-label="Click for more information about this combination"
          >
            <span>More Info</span>
          </button>
        </div>
      )}
    </article>
  )
}

export default ComboCard
