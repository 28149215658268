export const sectionsContent = [
  {
    headline: 'Get started with GraphQL Studio',
    description:
      'Create your own GraphQL API in just a few clicks! No resolvers to write. No data connections to code. No servers to build - your API runs on StepZen.',
    icon: {
      src: '/tutorial-graphics/lightning.svg',
      alt: 'A lightning bolt',
    },
    highlights: [
      {
        headline: 'Select schemas or combinations to make your API',
        description:
          'Connect the data you need by selecting one or more pre-built GraphQL schemas or a combination.',
        media: {
          type: 'video',
          src: '/tutorial-highlights/1.1.mp4',
          poster: '/tutorial-highlights/tutorial-cover.jpg',
          alt: '',
        },
      },
      {
        headline: 'Use mock data to explore schemas',
        description:
          'No keys required! Explore and test the API you created right away by querying against mock data.',
        media: {
          type: 'image',
          src: '/tutorial-highlights/1.2.png',
          alt: '',
        },
      },
      {
        headline: 'Add your own keys and query YOUR data',
        description:
          'See your API connected to your own data. Add API keys for your services and see it all in action!',
        media: {
          type: 'image',
          src: '/tutorial-highlights/1.3.png',
          alt: '',
        },
      },
    ],
  },
  {
    headline: 'Explore your API',
    description:
      'Stepzen allows you to run sample queries, see responses, view code and documentation, and even edit your queries. ',
    icon: {
      src: '/tutorial-graphics/play.svg',
      alt: 'A play icon',
    },
    highlights: [
      {
        headline: 'Run queries, see results',
        description:
          'Stepzen-crafted sample queries and responses show your API in action, with mock data or yours.',
        media: {
          type: 'image',
          src: '/tutorial-highlights/2.1.png',
          alt: '',
        },
      },
      {
        headline: 'Examine Schema Code',
        description: 'The code generated for your API is a tab away.',
        media: {
          type: 'image',
          src: '/tutorial-highlights/2.2.png',
          alt: '',
        },
      },
      {
        headline: 'View the docs for your API ',
        description:
          'Documentation for your selected schemas is easy to search and manage.',
        media: {
          type: 'image',
          src: '/tutorial-highlights/2.3.png',
          alt: '',
        },
      },
    ],
  },
  {
    headline: 'Deploy & Run Your API on StepZen',
    description:
      "Your APIs is live. No servers or infrastructure to manage. StepZen's managed service ensures it is always on, secured, and performant.",
    icon: {
      src: '/tutorial-graphics/upload.svg',
      alt: 'An up arrow in the center of a buttom',
    },
    highlights: [
      {
        headline: 'Access your endpoint URL',
        description:
          'It’s your API, put it to work. Sign up for a free account to build on your API and we’ll run it for you, forever.',
        media: {
          type: 'image',
          src: '/tutorial-highlights/3.1.png',
          alt: '',
        },
      },
      {
        headline: 'Open or Download your API ',
        description:
          'Open your deployed API in the GraphiQL editor to work locally or download your API and configuration as a .ZIP bundle.',
        media: {
          type: 'image',
          src: '/tutorial-highlights/3.2.png',
          alt: '',
        },
      },
      {
        headline: 'Dig Deep with GraphQL and StepZen',
        description:
          "See how StepZen's platform and tools  help you build a single API out of one data source or 100. We'll manage it for you and help integrate it into Netlify and other frameworks. ",
        media: {
          type: 'image',
          src: '/tutorial-highlights/3.3.png',
          alt: '',
        },
      },
    ],
  },
]
