import {useContext} from 'react'

import {statusContext} from '../../contexts/status'

import ComboAdd from '../ComboAdd'
import Overlay from '../Overlay'

import icons from '../../assets/images/icons.svg'
import communityBadge from '../../assets/images/icons/community.svg'
import github from '../../assets/images/github.svg'

function ComboDetails() {
  const {comboDetailstoShow, setComboDetailstoShow} = useContext(statusContext)
  const combination = comboDetailstoShow

  const handleClose = evt => {
    setComboDetailstoShow(null)
  }

  return (
    <Overlay extraClasses="comboDetails-overlay">
      <div class="comboDetails">
        <header className="comboDetails-header">
          <img
            src={communityBadge}
            alt=""
            aria-hidden="true"
            height="54"
            className="comboDetails-badge"
          />

          <div className="comboDetails-header-content">
            <h2 className="comboDetails-title">{combination.name}</h2>
            <p className="comboDetails-includes">
              {combination.includes.join(' + ')}
            </p>
          </div>

          <button
            className="button cancelButton"
            onClick={handleClose}
            aria-label="Close combination details"
          >
            <span>
              <svg aria-hidden="true" className="icon">
                <use xlinkHref={`${icons}#cancel`}></use>
              </svg>
            </span>
          </button>
        </header>

        <div class="comboDetails-meta">
          {combination.author.url ? (
            <a
              href={combination.author.url}
              className="comboDetails-author"
              target="_blank"
              rel="noreferrer noopener"
              title={`View ${combination.author.name} on GitHub`}
            >
              <img src={github} alt="GitHub icon" height="22" />
              {combination.author.name}
            </a>
          ) : (
            <span className="comboDetails-author">
              {combination.author.name}
            </span>
          )}

          <ComboAdd combination={combination} />
        </div>

        <div className="comboDetails-description">
          <p>{combination.description}</p>
        </div>
      </div>
    </Overlay>
  )
}

export default ComboDetails
