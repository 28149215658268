const fn = colors => {
  if (colors.length === 1) {
    return `linear-gradient(0deg, ${colors[0]} 0%, ${colors[0]} 100%)`
  }

  let list = []

  if (colors.length === 3) {
    list.push(`${colors[0]} 0%`)
    list.push(`${colors[0]} 39%`)
    list.push(`${colors[1]} 41%`)
    list.push(`${colors[1]} 59%`)
    list.push(`${colors[2]} 61%`)
    list.push(`${colors[2]} 100%`)
    return `linear-gradient(0deg, ${list.join(', ')})`
  }

  if (colors.length === 4) {
    list.push(`${colors[0]} 0%`)
    list.push(`${colors[0]} 29%`)
    list.push(`${colors[1]} 31%`)
    list.push(`${colors[1]} 49%`)
    list.push(`${colors[2]} 51%`)
    list.push(`${colors[2]} 69%`)
    list.push(`${colors[3]} 71%`)
    list.push(`${colors[3]} 100%`)
    return `linear-gradient(0deg, ${list.join(', ')})`
  }

  let percent = 100 / colors.length

  let current = 0

  for (const color of colors) {
    list.push(`${color} ${current}%`)
    list.push(`${color} ${current + percent}%`)
    current += percent
  }

  return `linear-gradient(0deg, ${list.join(', ')})`
}

export default fn
