import Tippy from '@tippyjs/react'
import {useContext, useState} from 'react'

import {endpointContext} from '../../contexts/endpoint'

import icons from '../../assets/images/icons.svg'

const {REACT_APP_SERVER_URL} = process.env

function Endpoint() {
  const {endpoint, selected} = useContext(endpointContext)
  const [copyMessage, setCopyMessage] = useState('Copy')

  const download = `${REACT_APP_SERVER_URL}/download?templates=${selected
    ?.map(i => i.name)
    .join(',')}`

  const handleOnCopy = () => {
    navigator.clipboard.writeText(endpoint?.url)
    setCopyMessage('')
    setTimeout(function () {
      setCopyMessage('Copy')
    }, 5000)
  }

  return (
    <>
      <form className="endpointForm">
        <div className="field copy-field inline-label mb-1">
          <label htmlFor="endpoint-url">Endpoint</label>
          <input id="endpoint-url" readOnly type="text" value={endpoint.url} />
          <Tippy
            content={copyMessage ? copyMessage : 'Copied!'}
            hideOnClick={false}
          >
            <button
              aria-label="Copy"
              className="button copy-code"
              disabled={!endpoint?.url}
              onClick={handleOnCopy}
              type="button"
            >
              <svg aria-hidden="true" className="icon">
                <use xlinkHref={`${icons}#copy`}></use>
              </svg>
            </button>
          </Tippy>
        </div>
      </form>

      <ul className="cardList">
        <li
          className="card cardList-card"
          data-analytics-action="openInGraphiQL"
          data-analytics-data={endpoint.url}
        >
          <h2>Explore in GraphiQL</h2>
          <p>Get to know your API in the standard GraphiQL editor.</p>
          <a
            className="button color4 small cover"
            href={endpoint?.url}
            rel="noreferrer"
            target="_blank"
          >
            <span>Open in GraphiQL</span>
          </a>
        </li>
        <li
          className="card cardList-card"
          data-analytics-action="downloadSchema"
          data-analytics-data={download}
        >
          <h2>Download and Build</h2>
          <p>
            Get a .ZIP file containing schema starter files and configuration to
            use with your StepZen account or anywhere else.
          </p>
          <a
            className="button color4 small cover"
            disabled={selected.length === 0}
            href={download}
          >
            <span>Download</span>
          </a>
        </li>
      </ul>
    </>
  )
}

export default Endpoint
