import {Link} from 'react-router-dom'
import {useContext} from 'react'

import EndpointField from '../EndpointField'

import {getUrl} from '../../utils/url-helper'
import {isEmptyObject} from '../../utils/helpers'
import {PAGE_PATHS} from '../../utils/constants'

import {authenticationContext} from '../../contexts/authentication'
import {endpointContext} from '../../contexts/endpoint'
import {statusContext} from '../../contexts/status'

import icons from '../../assets/images/icons.svg'
import logo from '../../assets/images/mark.svg'

const {REACT_APP_STEPZEN_WEBSITE_DOMAIN} = process.env

const Header = () => {
  const {endpoint, selected} = useContext(endpointContext)
  const {isLoading, setShowTutorial} = useContext(statusContext)
  const {loginFn, loginUrl, logoutUrl, user} = useContext(authenticationContext)

  const handleOnShowTutorial = evt => {
    setShowTutorial(true)
  }

  return (
    <header className="app-header">
      <span className="brand">
        <a
          href={REACT_APP_STEPZEN_WEBSITE_DOMAIN}
          aria-label="Go to StepZen.com"
          className={isLoading ? 'logo loading' : 'logo'}
        >
          <img src={logo} alt="The StepZen icon" height="30" />
        </a>
        <Link to={getUrl(PAGE_PATHS.list, selected)} className="brand-appName">
          GraphQL Studio
        </Link>
      </span>

      <div className="app-header-actions">
        <EndpointField />
        {endpoint && endpoint?.url ? (
          <a
            className="button color9 publishButton"
            data-analytics-action="publish"
            data-analytics-data={endpoint?.url}
            disabled={!endpoint?.url}
            href={`${REACT_APP_STEPZEN_WEBSITE_DOMAIN}/getting-started?details=examples&example=graphql&studiourl=${endpoint?.url}`}
          >
            <span>Publish</span>
          </a>
        ) : (
          <a
            className="button color9 publishButton"
            data-analytics-action="publish"
            data-analytics-data={endpoint?.url}
            disabled={!endpoint?.url}
            href={`${REACT_APP_STEPZEN_WEBSITE_DOMAIN}/getting-started?details=examples&example=graphql`}
          >
            <span>Publish</span>
          </a>
        )}

        {!isEmptyObject(user) && user ? (
          <a className="button secondary color3 logoutButton" href={logoutUrl}>
            <span>Log Out</span>
          </a>
        ) : (
          <a
            className="button color11"
            href={loginUrl}
            onClick={loginFn}
            data-analytics-action="createAccount"
            data-analytics-data={loginUrl}
          >
            <span>Create account</span>
          </a>
        )}

        <button className="button linkButton" onClick={handleOnShowTutorial}>
          <span className="button-wrap">
            <svg className="icon xsmall" aria-hidden="true">
              <use xlinkHref={`${icons}#help`}></use>
            </svg>
            <span>Help</span>
          </span>
        </button>
      </div>
    </header>
  )
}

export default Header
