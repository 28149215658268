import Header from '../Header'
import AppNav from '../AppNav'

import {useContext} from 'react'
import {statusContext} from '../../contexts/status'

const Layout = ({children}) => {
  const {viewShown} = useContext(statusContext)

  return (
    <div className={`view view-${viewShown}`}>
      <Header />

      <div className="app-main">
        <AppNav />
        {children}
      </div>
    </div>
  )
}

export default Layout
