import {useContext, useEffect, useState} from 'react'

import {TextInput} from '../Forms'
import icons from '../../assets/images/icons.svg'

import {authenticationContext} from '../../contexts/authentication'
import {settingsContext} from '../../contexts/settings'

function TemplateSettings() {
  const [details, setDetails] = useState({})

  const {questions, setEditing, template} = useContext(settingsContext)
  const {loginFn, loginUrl, user} = useContext(authenticationContext)

  useEffect(() => {
    const state = {}
    for (const question of questions) {
      const key = question.name.split('.')[1]
      const value = window.localStorage.getItem(key)
      state[key] = value
    }
    setDetails(state)
  }, [questions])

  const handleOnCancel = evt => {
    setEditing(null)
  }

  const handleOnChange = evt => {
    const {name, value} = evt.target

    const updated = {
      ...details,
      [name]: value,
    }

    setDetails(updated)
  }

  const handleOnKeyDown = evt => {
    if (evt.key === 'Enter') {
      evt.preventDefault()
      evt.stopPropagation()

      handleOnSave(evt)
    }

    if (evt.key === 'Escape') {
      setEditing(null)
    }
  }

  const handleOnSave = evt => {
    evt.preventDefault()

    for (const [name, value] of Object.entries(details)) {
      if (value) {
        window.localStorage.setItem(name, value)
      } else {
        window.localStorage.removeItem(name)
      }
    }

    setEditing(null)
  }

  return (
    <div className="app-overlay schema-config">
      <div className="col-wrapper">
        <div className="add-form">
          <header className="form-header">
            <div className="form-header-instruction">
              <h2 className="weight-normal">
                Configure <strong>{template.metadata.name}</strong>
              </h2>
            </div>
            <div className="button-group">
              <button
                className="button color11"
                onClick={handleOnSave}
                type="button"
                data-analytics-action="addConfig"
                data-analytics-data={template.metadata.name}
              >
                <span>Save</span>
              </button>
              <button
                className="button cancelButton"
                onClick={handleOnCancel}
                aria-label="Close schema configuration"
              >
                <span>
                  <svg aria-hidden="true" className="icon">
                    <use xlinkHref={`${icons}#cancel`}></use>
                  </svg>
                </span>
              </button>
            </div>
          </header>
          <fieldset className="object-list">
            {questions?.map(question => (
              <div className="card config-card">
                <div className="field" key={`question-${question.name}`}>
                  <label htmlFor={question.name.split('.')[1]}>
                    {question.message}
                  </label>
                  <TextInput
                    id={question.name.split('.')[1]}
                    name={question.name.split('.')[1]}
                    onChange={handleOnChange}
                    onKeyDown={handleOnKeyDown}
                    type={question.type || 'password'}
                    value={details[question.name.split('.')[1]]}
                  />
                </div>
              </div>
            ))}
          </fieldset>
        </div>

        <aside className="schema-config-help">
          <h2 className="h3">Will my keys be secure?</h2>
          <p>
            <strong>
              Your keys are stored in localStorage on your machine, they are
              never stored on StepZen.
            </strong>
          </p>
          {user ? null : (
            <>
              <p>
                Sign up for a free StepZen account to manage and grow your
                endpoint with custom directives, tools, support, and more.{' '}
              </p>
              <a className="button secondary" href={loginUrl} onClick={loginFn}>
                <span>Create account</span>
              </a>
            </>
          )}
        </aside>
      </div>
    </div>
  )
}

export default TemplateSettings
